import { TRACK_EVENTS } from "@recare/core/consts";
import { useInterval } from "@recare/core/hooks";
import { useCallback, useState } from "react";
import { useTracking } from "react-tracking";
import request from "superagent";
import {
  INITIAL_RETRY_INTERVAL,
  MINIMUM_DISPLAY_INTERVAL,
  PING_URL,
  STANDARD_INTERVAL,
} from "./config";

const backoffInterval = (i: number) => (2 ** i - 1) * INITIAL_RETRY_INTERVAL;

function calcWaitTime(pings: number) {
  let waitTime = 0;
  for (let i = pings; i > 0; i--) {
    waitTime += backoffInterval(i);
  }
  return waitTime;
}

export default function useNetworkChecker(): [number, () => void] {
  const [resetPings, setResetPings] = useState<Array<number>>([]);
  const [failedPings, setFailedPings] = useState<number>(0);
  const [isGithubUp, setIsGithubUp] = useState<boolean>(true);
  const { trackEvent } = useTracking();

  async function failure() {
    try {
      await request("GET", "https://api.github.com/");
    } catch (error) {
      setIsGithubUp(false);
    }
    setFailedPings(failedPings + 1);
  }

  const success = useCallback(() => {
    if (failedPings > 1 || (failedPings == 1 && resetPings.length > 0)) {
      const cumulativePings = resetPings.reduce(
        (accumulatedPings, ping) => accumulatedPings + ping,
        0,
      );
      const cumulativeWaitTime = resetPings.reduce(
        (accumulatedTime, ping) => accumulatedTime + calcWaitTime(ping),
        0,
      );
      const did_reset = cumulativeWaitTime > 0;
      const reseted_wait_times = did_reset
        ? resetPings.map((ping) => calcWaitTime(ping))
        : undefined;
      const totalWaitTime = cumulativeWaitTime + calcWaitTime(failedPings);
      trackEvent({
        name: TRACK_EVENTS.NETWORK_CHECKER,
        failed_pings: failedPings + cumulativePings,
        wait_time: totalWaitTime,
        did_reset,
        reseted_wait_times,
        did_ping_github: isGithubUp,
        did_show_banner: totalWaitTime > MINIMUM_DISPLAY_INTERVAL,
      });
    }
    // reset state
    setFailedPings(0);
    setResetPings([]);
    setIsGithubUp(true);
  }, [resetPings, failedPings, isGithubUp]);

  const reset = useCallback(() => {
    const nextResetPings = resetPings.slice();
    nextResetPings.push(failedPings - 1);
    setResetPings(nextResetPings);
    setFailedPings(1);
  }, [resetPings, failedPings]);

  useInterval(
    () => {
      request("GET", PING_URL)
        .then(({ text }: { text: string }) => {
          if (text !== "pong") failure();
          else success();
        })
        .catch(() => {
          failure();
        });
    },
    failedPings > 0 ? backoffInterval(failedPings) : STANDARD_INTERVAL,
  );

  return [failedPings > 0 ? backoffInterval(failedPings) : 0, reset];
}

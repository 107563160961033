import { getErrorMessage } from "@recare/core/model/utils/errors";
import { useEffect, useState } from "react";

type InstallOutcome = "accepted" | "dismissed";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  prompt(): Promise<void>;
  readonly userChoice: Promise<{
    outcome: InstallOutcome;
    platform: string;
  }>;
}

export type InstallPromptContext = {
  canInstall: boolean;
  outcome?: InstallOutcome;
  promptToInstall: () => Promise<void>;
};

export const defaultInstallPromptContext: InstallPromptContext = {
  canInstall: false,
  promptToInstall: async () => {},
};

export function useAddToHomescreenPrompt() {
  const [prompt, setPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [outcome, setOutcome] = useState<InstallOutcome>();

  const promptToInstall = async () => {
    try {
      if (!prompt) {
        throw new Error(
          "tried installing before browser sent 'beforeinstallprompt' event",
        );
      }
      await prompt.prompt();
      const choice = await prompt.userChoice;
      setOutcome(choice.outcome);
      setPrompt(null);
    } catch (err) {
      console.error(`error prompting install: ${getErrorMessage(err)}`);
    }
  };

  useEffect(() => {
    const ready = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setPrompt(e);
    };

    window.addEventListener(
      "beforeinstallprompt",
      ready as (event: Event) => void,
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        ready as (event: Event) => void,
      );
      setPrompt(null);
    };
  }, []);

  return { canInstall: !!prompt, outcome, promptToInstall };
}

import transport from "@recare/core/model/api/transport";

type ConclusionType =
  | "action_required"
  | "cancelled"
  | "failure"
  | "neutral"
  | "success"
  | "timed_out";
type StatusType = "completed" | "in_progress" | "queued";

export function updateChromaStatus({
  conclusion,
  sha,
  status,
  url,
}: {
  conclusion: ConclusionType;
  sha: string;
  status: StatusType;
  url?: string;
}) {
  return transport.post({
    route: "/checks",
    body: {
      status,
      conclusion,
      url,
      sha,
    },
    options: { url: "https://router-pr.staging.recaresolutions.com" },
  });
}

import { withStyles } from "@mui/styles";
import { ReactNode } from "react";

const html = {
  // Change from `box-sizing: content-box` so that `width`
  // is not affected by `padding` or `border`.
  boxSizing: "border-box",
};

const styles = function styles() {
  return {
    "@global": {
      html: html,
      "*, *::before, *::after": {
        boxSizing: "inherit",
      },
    },
  };
};

export function CssBaseline({ children }: { children?: ReactNode }) {
  return <>{children}</>;
}

export default withStyles(styles, {
  name: "CssBaseline",
})(CssBaseline);

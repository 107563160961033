import { BackendConfig } from "@recare/core/model/config";

const BackendURL =
  BackendConfig.url ||
  `${BackendConfig.protocol}://${BackendConfig.host || ""}:${
    BackendConfig.port || ""
  }`;

const STANDARD_INTERVAL_MINUTES = 2;
export const STANDARD_INTERVAL = STANDARD_INTERVAL_MINUTES * 60 * 1000;
export const INITIAL_RETRY_INTERVAL = 500;
export const MINIMUM_DISPLAY_INTERVAL = 2 * 1000;
export const PING_URL = `${BackendURL}/ping`;

import {
  APP_VERSION_B2C,
  APP_VERSION_CAREPROVIDER,
  APP_VERSION_CLINIC,
  TRACK_EVENTS,
} from "@recare/core/consts";
import {
  BaseEventSchema,
  baseEventSchema,
  baseEventSchemaB2C,
  baseEventSchemaCareprovider,
  baseEventSchemaSender,
  contactAnywayConfirmButtonSchema,
  contactReceiverButtonSchema,
  editPatientButtonSchema,
  messengerSendMessageSchema,
  printButtonConfirmedSchema,
  requestActionButtonSchema,
  requestPanelSchema,
  searchReceiversButtonSchema,
  tableSortingButtonSchema,
  userManagementUpdateAccountSchema,
} from ".";

export default function validateTrackingEventData({
  events,
}: {
  events: BaseEventSchema[];
}) {
  events.forEach(
    ({
      name,
      page_information,
      platform_information,
      session_information,
      system_event,
      timestamp,
      user_information,
      ...customEventData
    }) => {
      /** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       *  Validate base event data strictly against base event schema
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/
      try {
        const baseEventData = {
          name,
          page_information,
          platform_information,
          session_information,
          timestamp,
          user_information,
          system_event,
        };
        switch (platform_information.application) {
          case APP_VERSION_CLINIC:
            baseEventSchemaSender.parse(baseEventData);
            break;
          case APP_VERSION_CAREPROVIDER:
            baseEventSchemaCareprovider.parse(baseEventData);
            break;
          case APP_VERSION_B2C:
            baseEventSchemaB2C.parse(baseEventData);
            break;
          default:
            baseEventSchema.strict().parse(baseEventData);
        }

        /** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
         *  Validate custom event data against customly defined event schemas
         * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/

        switch (name) {
          case TRACK_EVENTS.UM_DEACTIVATE_CLICKED:
          case TRACK_EVENTS.UM_DEACTIVATE_ERROR:
          case TRACK_EVENTS.UM_DEACTIVATE_MODAL_CANCELLED:
          case TRACK_EVENTS.UM_DEACTIVATE_MODAL_CONFIRMED:
          case TRACK_EVENTS.UM_DEACTIVATE_SUCCESS:
          case TRACK_EVENTS.UM_EDIT_CLICKED:
          case TRACK_EVENTS.UM_EDIT_ERROR:
          case TRACK_EVENTS.UM_EDIT_MODAL_CANCELLED:
          case TRACK_EVENTS.UM_EDIT_MODAL_CONFIRMED:
          case TRACK_EVENTS.UM_EDIT_SUCCESS:
          case TRACK_EVENTS.UM_REACTIVATE_CLICKED:
          case TRACK_EVENTS.UM_REACTIVATE_ERROR:
          case TRACK_EVENTS.UM_REACTIVATE_MODAL_CANCELLED:
          case TRACK_EVENTS.UM_REACTIVATE_MODAL_CONFIRMED:
          case TRACK_EVENTS.UM_REACTIVATE_SUCCESS:
          case TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_CLICKED:
          case TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_ERROR:
          case TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_MODAL_CANCELLED:
          case TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_MODAL_CONFIRMED:
          case TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_SUCCESS:
          case TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_CLICKED:
          case TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_ERROR:
          case TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_MODAL_CANCELLED:
          case TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_MODAL_CONFIRMED:
          case TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_SUCCESS:
            userManagementUpdateAccountSchema.parse({
              name,
              ...customEventData,
            });
            break;

          case TRACK_EVENTS.PRINT_BUTTON_CONFIRMED:
            printButtonConfirmedSchema.parse(customEventData);
            break;
          case TRACK_EVENTS.REQUEST_ACTION:
            requestActionButtonSchema.parse({ name, ...customEventData });
            break;
          case TRACK_EVENTS.REQUEST_PANEL_ACCEPT:
          case TRACK_EVENTS.REQUEST_PANEL_VALIDATE:
          case TRACK_EVENTS.REQUEST_PANEL_DECLINE:
          case TRACK_EVENTS.REQUEST_PANEL_REJECT:
            requestPanelSchema.parse({ name, ...customEventData });
            break;
          case TRACK_EVENTS.MESSENGER_SEND_MESSAGE_BUTTON:
            messengerSendMessageSchema.parse({ name, ...customEventData });
            break;
          case TRACK_EVENTS.EDIT_PATIENT_BUTTON_CLICKED:
            editPatientButtonSchema.parse({ name, ...customEventData });
            break;
          case TRACK_EVENTS.CONTACT_RECEIVER_BUTTON_CLICKED:
            contactReceiverButtonSchema.parse({ name, ...customEventData });
            break;
          case TRACK_EVENTS.TABLE_SORT_BY_DEFAULT_CLICKED:
          case TRACK_EVENTS.TABLE_SORT_BY_DISTANCE_CLICKED:
          case TRACK_EVENTS.TABLE_SORT_BY_NAME_CLICKED:
            tableSortingButtonSchema.parse({ name, ...customEventData });
            break;
          case TRACK_EVENTS.SEARCH_PROVIDER_BUTTON_CLICKED:
            searchReceiversButtonSchema.parse({ name, ...customEventData });
            break;
          case TRACK_EVENTS.CONTACT_ANYWAY_MODAL_CONFIRM_BUTTON_CLICKED:
            contactAnywayConfirmButtonSchema.parse({
              name,
              ...customEventData,
            });
            break;
          default:
            return;
        }
      } catch (err) {
        console.error(`Event ${name} - ${JSON.stringify(err)}`);
      }
    },
  );
}

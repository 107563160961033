import { APP_VERSION_NAMES } from "@recare/core/consts";
import { useGetPageName } from "@recare/core/model/tracker/utils";
import { AlgoliaAnalyticsName } from "@recare/core/types";
import { useEnvContext } from "context/EnvContext";
import { capitalize } from "lodash";
import { useParams } from "react-router-dom";

export function useGetAnalyticsTags({
  algoliaAnalyticsName,
}: {
  algoliaAnalyticsName: AlgoliaAnalyticsName;
}) {
  const { app } = useEnvContext();
  const params = useParams();
  const { pageName, staticUrl } = useGetPageName();

  const isPatientUrl = staticUrl?.startsWith(
    "/app/clinic/dashboard/patient/:patient_id/auction/:auction_id/",
  );
  const tabName = params["*"];
  const pageNameWithTabs = `${pageName}${
    isPatientUrl ? ` - ${capitalize(tabName)}` : ""
  }`;

  return [
    APP_VERSION_NAMES[app],
    algoliaAnalyticsName,
    pageNameWithTabs,
  ] as const;
}

import { useApolloClient } from "@apollo/client";
import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import SealdContextProvider from "@recare/core/seald/SealdContext";
import { AppType, QueryProgress } from "@recare/core/types";
import { useEnvContext } from "context/EnvContext";
import { RefreshBannerSealdSDK } from "dsl/atoms/RefreshBanners";
import { SealdWarningBanner } from "dsl/atoms/RefreshBanners/SealdWarningBanner";
import { useState } from "react";
import { useOnLogout } from "reduxentities/actions";
import { useConnecterWithLoggedAccount } from "reduxentities/selectors/hooks";

export default function WebSealdContextProvider({
  app,
  children,
}: {
  app: AppType;
  children: React.ReactNode;
}) {
  const loggedAccount = useConnecterWithLoggedAccount({ cacheOnly: true });
  const envContext = useEnvContext();
  const onLogout = useOnLogout();
  const client = useApolloClient();

  const [sealdStatus, setSealdStatus] = useState<QueryProgress>(
    QUERY_PROGRESS_PENDING,
  );

  return (
    <SealdContextProvider
      loggedAccount={loggedAccount}
      envContext={envContext}
      app={app}
      onLogout={onLogout}
      apolloClient={client}
    >
      {([APP_CAREPROVIDER, APP_CLINIC] as AppType[]).includes(app) && (
        <SealdWarningBanner
          sealdStatus={sealdStatus}
          onSealdStatusChange={setSealdStatus}
        />
      )}
      {sealdStatus === QUERY_PROGRESS_SUCCEED && <RefreshBannerSealdSDK />}
      {children}
    </SealdContextProvider>
  );
}

// https://mui.com/material-ui/react-link/
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { formatPhone } from "@recare/core/model/utils/strings";
import { useTranslations } from "@recare/translations";
import { KEYBOARD_FOCUS_OUTLINE } from "ds/materials/colors";
import { dp, padding } from "ds/materials/metrics";
import { FONT_FAMILY, FONT_WEIGHT_BOLD } from "ds/materials/typography";
import { Link as RouterLink, To } from "react-router-dom";

type LinkProps = MuiLinkProps &
  (
    | {
        href?: never;
        to: To;
      }
    | { href: string; to?: never }
  );

const getDefaultSx = (): CSSProperties => ({
  boxSizing: "border-box",
  fontFamily: FONT_FAMILY,
  fontWeight: FONT_WEIGHT_BOLD,
  padding: padding(0, 0.25),
  "&:focus-visible": {
    outlineOffset: dp(-0.25),
    outline: `${dp(2)} solid ${KEYBOARD_FOCUS_OUTLINE}`,
    textDecoration: "underline",
    textDecorationColor: "inherit",
  },
});

export type ExternalLinkType = "email" | "website" | "pdf" | "phone";

export const useExternalLinkLabel = () => {
  const translations = useTranslations();

  const detail: Record<ExternalLinkType, string> = {
    email: translations.providersearch.genericAriaLabelEmailLink,
    pdf: translations.providersearch.genericAriaLabelPDFLink,
    website: translations.providersearch.genericAriaLabelLink,
    phone: translations.providersearch.genericAriaLabelPhoneLink,
  };

  return function externalLinkLabel(
    label: string,
    type: ExternalLinkType = "website",
  ) {
    if (type === "phone") {
      return `${detail[type]} - ${formatPhone(label)}`;
    }
    return `${label} - ${detail[type]}`;
  };
};

function LinkV2({ children, href, to, ...props }: LinkProps) {
  const defaultSx = getDefaultSx();

  if (to) {
    return (
      <MuiLink
        component={RouterLink}
        to={to}
        underline="hover"
        {...props}
        sx={{ ...defaultSx, ...props.sx }}
      >
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink
      href={href}
      rel={props.target === "_blank" ? "noopener noreferrer" : undefined}
      underline="hover"
      {...props}
      sx={{ ...defaultSx, ...props.sx }}
    >
      {children}
    </MuiLink>
  );
}

export default LinkV2;

/**
 * This file interacts with a library that can have access and potentially expose our document data to third party vendors.
 * Have in mind any changes to existing interactions need to be carefully reviewed,
 * since we have sensitive patient data rendered in varied nodes throughout the pages.
 */
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import Config from "@recare/core/model/config";
import { ServiceFactoryAppString } from "containers/ServiceFactory";
import { activateRealUserMonitoring } from "../utils/featureFlags";

const appIdAndToken: {
  [key in ServiceFactoryAppString]: {
    applicationId: RumInitConfiguration["applicationId"];
    clientToken: RumInitConfiguration["clientToken"];
    service: RumInitConfiguration["service"];
  };
} = {
  Clinic: {
    applicationId: "fb70a9c9-c3e8-4ff8-812c-e386be12faf9",
    clientToken: "pub9bf1973142590c5fbbc8eadc58e86caa",
    service: "app",
  },
  Careprovider: {
    applicationId: "0cbf4190-8885-49e7-b437-6ee53533ee85",
    clientToken: "pub937ed0a09716b950754bdf709182b8dc",
    service: "partner",
  },
  B2C: {
    applicationId: "12a3bfd0-906a-47c2-874a-245c404cbfe7",
    clientToken: "pub7fc67fd86857a9f9ac6cc646a6acf7f5",
    service: "b2c",
  },
  ProviderSearch: {
    applicationId: "bdaf7dd5-63b3-485f-9ad8-1da2d2777dcd",
    clientToken: "pubf25f3c68cf217882da91b69be92b2c56",
    service: "providersearch",
  },
  ACP: {
    applicationId: "05cb02d8-9c7c-477d-8acf-2fd1f2534f9a",
    clientToken: "pube8a2b45c0e43d24abeab1e64e79ad17c",
    service: "acp",
  },
};

export function configureRealUserMonitoring(app: ServiceFactoryAppString) {
  const { version } = Config;
  const env = process.env.ENV;
  const appConfig = appIdAndToken[app];

  if (!activateRealUserMonitoring || !version || !appConfig || !env) {
    return;
  }

  datadogRum.init({
    ...appConfig,
    site: "datadoghq.eu",
    env,
    version,
    sessionSampleRate: 100,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
    /**
     * from https://docs.datadoghq.com/real_user_monitoring/browser/
     * The trackUserInteractions parameter enables the automatic collection of user clicks in your application. 
     * Sensitive and private data contained in your pages may be included to identify the elements interacted with.
     */
    trackUserInteractions: false,
    allowedTracingUrls: [
      "https://api-preprod.recaresolutions.com",
      "https://api.recaresolutions.com",
    ],
  });
}

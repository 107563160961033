import { TrackEventFn } from "@recare/core/types";
import { ComponentType } from "react";
import { useTracking } from "react-tracking";

export type WithTrackingEventProps = {
  trackEvent: TrackEventFn;
};

export function withTrackingEvent<
  T extends WithTrackingEventProps = WithTrackingEventProps,
>(WrappedComponent: ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithTheme = (props: Omit<T, keyof WithTrackingEventProps>) => {
    const tracking = useTracking();
    return <WrappedComponent {...tracking} {...(props as T)} />;
  };

  ComponentWithTheme.displayName = `withTracking(${displayName})`;

  return ComponentWithTheme;
}

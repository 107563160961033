import { TRACK_EVENTS } from "@recare/core/consts";
import api from "@recare/core/model/api";
import { ComponentType, lazy } from "react";

const MAX_TRIES = 5;
const BASE_INTERVAL = 500;
const INITIAL_ITERATION = 1;

const backoffInterval = (i: number) => (2 ** i - 1) * BASE_INTERVAL;

// Lazy loading error retrying with exponential backoff
// source: https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
function retry<T>({
  fn,
  retriesLeft = MAX_TRIES,
  iterationNb = INITIAL_ITERATION,
}: {
  fn: () => Promise<{
    default: ComponentType<T>;
  }>;
  iterationNb?: number;
  retriesLeft?: number;
}): Promise<{
  default: ComponentType<T>;
}> {
  let importName = fn.toString();
  const retries = retriesLeft;
  const iteration = iterationNb;
  importName = importName.substring(
    importName.lastIndexOf("./"),
    importName.lastIndexOf(".js"),
  );
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retries === 1) {
            api.analytics.track({
              name: TRACK_EVENTS.LAZY_LOADING_CHUNK_ERROR,
              // @ts-ignore
              iteration,
              chunk: importName,
            });
            reject(new Error(`Lazy Loading chunk '${importName}' failed.`));
            return;
          }

          // retrying
          api.analytics.track({
            name: TRACK_EVENTS.LAZY_LOADING_CHUNK_RETRY,
            // @ts-ignore
            iteration,
            chunk: importName,
          });
          retry({
            fn,
            retriesLeft: retries - 1,
            iterationNb: iteration + 1,
          }).then(resolve, reject);
        }, backoffInterval(iteration));
      });
  });
}
export default <T>(
  importFn: () => Promise<{
    default: ComponentType<T>;
  }>,
) => lazy(() => retry({ fn: importFn }));

import { List } from "immutable";
import { PropsWithChildren, useEffect, useMemo, useRef } from "react";
import { Observable, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ObservableValue, stateDispatcherContext } from "./Context";
import { FormElementRenderProps } from "./FormElement";
import { INITIAL_CHANGE_EVENT_TYPE } from "./FormEvents";
import { getValue } from "./StateValueHelpers";
import { Whitelist, _StateDispatcherProps } from "./types";

type InjectedStateDispatcherProps = {
  onChange: (newValue: any, statePath?: string | undefined) => void;
};

function Dispatcher(
  props: PropsWithChildren<{
    convertIn: (value: any, props?: any) => any;
    convertOut: (value: any, props?: any) => any;
    getValue: any;
    onChange: (value: any, statePath: string, validation: any) => void;
    rootValueChangeObs: Observable<ObservableValue> | null;
    statePath: string;
    valueChangeObs: Observable<ObservableValue>;
    whitelist?: Whitelist;
  }>,
) {
  const handlers = useRef<{ set?: any }>({});
  const valueChangeObs = useMemo(
    () =>
      props.valueChangeObs.pipe(
        map((e) => {
          const convertedValue = props.convertIn(e.value, props);

          if (convertedValue === e.value) return e;

          return Object.assign({}, e, {
            value: convertedValue,
            validation: e.validation,
            statePath:
              e.type === INITIAL_CHANGE_EVENT_TYPE
                ? undefined
                : props.statePath,
          });
        }),
      ),

    [],
  );

  const subscriptionRef = useRef<Subscription | null>(null);

  useEffect(() => {
    subscriptionRef.current = valueChangeObs
      .pipe(filter((e) => e.type === INITIAL_CHANGE_EVENT_TYPE))
      .subscribe({
        next: (event: any) => {
          if (handlers.current)
            Object.keys(handlers.current).forEach((statePath) => {
              if (
                handlers.current &&
                (event.oldValue === undefined ||
                  getValue(props.convertIn(event.value, props), statePath) !==
                    getValue(props.convertIn(event.oldValue, props), statePath))
              ) {
                handlers.current[
                  statePath as keyof typeof handlers.current
                ].set(getValue(props.convertIn(event.value, props), statePath));
              }
            });
        },
      });

    return () => {
      if (subscriptionRef.current) subscriptionRef.current.unsubscribe();
    };
  }, []);

  return (
    <stateDispatcherContext.Provider
      value={{
        whitelist: props.whitelist,
        onStateChange: (v: any, statePath: string, validation: any) => {
          const convertedInParentValue = props.convertIn(
            props.getValue(),
            props,
          );
          const completeValue =
            Array.isArray(convertedInParentValue) === false
              ? Object.assign({}, convertedInParentValue, {
                  [statePath]: v,
                })
              : List()
                  .concat(convertedInParentValue)
                  .toList()
                  .update((list: any) =>
                    list.update(
                      list.findIndex(
                        (item: { id: string }) => item.id === statePath,
                      ),
                      (item: any) => Object.assign({}, item, { value: v }),
                    ),
                  );
          const convertedValue = props.convertOut(completeValue, props);

          if (
            convertedValue !== completeValue ||
            typeof convertedValue !== typeof props.getValue()
          )
            props.onChange(convertedValue, props.statePath, validation);
          else {
            props.onChange(
              v,
              `${props.statePath}${
                props.statePath === "" || statePath === "" ? "" : "."
              }${statePath}`,
              validation,
            );
          }
        },
        rootValueChangeObs: props.rootValueChangeObs,
        valueChangeObs,
      }}
    >
      {props.children}
    </stateDispatcherContext.Provider>
  );
}

export function StateDispatcher(
  convertIn: (value: any, props?: any) => any = (v: any) => v,
  convertOut: (value: any, props?: any) => any = (v: any) => v,
  whitelist?: Whitelist,
) {
  return function withStateDispatcher<
    T extends _StateDispatcherProps = _StateDispatcherProps,
  >(WrappedComponent: React.ComponentType<InjectedStateDispatcherProps & T>) {
    // Try to create a nice displayName for React Dev Tools.
    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithStateDispatcher = ({
      elementName,
      ...props
    }: Omit<T, keyof Omit<InjectedStateDispatcherProps, "onChange">>) => {
      return (
        <FormElementRenderProps root elementName={elementName} {...props}>
          {({ onChange, ...formElementProps }) => (
            <Dispatcher
              convertIn={convertIn}
              convertOut={convertOut}
              whitelist={whitelist}
              {...props}
              onChange={onChange}
              {...formElementProps}
              elementName={elementName}
            >
              <WrappedComponent
                onChange={onChange}
                {...(props as T)}
                elementName={elementName}
              />
            </Dispatcher>
          )}
        </FormElementRenderProps>
      );
    };

    ComponentWithStateDispatcher.displayName = `withStateDispatcher(${displayName})`;

    return ComponentWithStateDispatcher;
  };
}

export function StateDispatcherRenderProps({
  convertIn = (v: any) => v,
  convertOut = (v: any) => v,
  elementName,
  whitelist,
  children,
  ...props
}: {
  children: (props: {
    elementName?: string;
    onChange: (newValue: any, statePath?: string | undefined) => void;
  }) => React.ReactElement | null;
  convertIn?: (value: any, props?: any) => any;
  convertOut?: (value: any, props?: any) => any;
  elementName?: string;
  onChange: (value: any, statePath: string, validation: any) => void;
  valueChangeObs: Observable<ObservableValue>;
  whitelist?: Whitelist;
}) {
  return (
    <FormElementRenderProps root elementName={elementName} {...props}>
      {({ onChange, ...formElementProps }) => (
        <Dispatcher
          whitelist={whitelist}
          convertIn={convertIn}
          convertOut={convertOut}
          {...props}
          {...formElementProps}
        >
          {children({ onChange, elementName })}
        </Dispatcher>
      )}
    </FormElementRenderProps>
  );
}

import { GET_LEGAL_DOCUMENTS } from "@recare/core/apollo/graphql";
import { APP_CAREPROVIDER, APP_CLINIC } from "@recare/core/consts";
import {
  getObjectLocalStorage,
  setObjectLocalStorage,
} from "@recare/core/model/localStorage";
import { getErrorMessage } from "@recare/core/model/utils/errors";
import { objectToQueryParamString } from "@recare/core/model/utils/strings";
import { LegalDocuments, QueryProgress } from "@recare/core/types";
import { useLazyQuery } from "apollo/hooks/queries/utils";
import { useEnvContext } from "context/EnvContext";
import { addDays, getUnixTime } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoggedCareseeker } from "reduxentities/selectors";
import { useLoggedCareproviderId } from "reduxentities/selectors/hooks";

type LegalDocumentsLocalStorageType = Record<
  string,
  { expiry: number; value: LegalDocuments }
>;

export type LegalDocumentPropsType =
  | {
      careproviderId?: number;
      careseekerId?: number;
    }
  | undefined;

export type LegalDocumentsContextType = {
  getLegalDocs: (props?: LegalDocumentPropsType) => LegalDocuments | undefined;
  progress: QueryProgress;
  setLegalDocs: (props?: LegalDocumentPropsType) => void;
};

const DEFAULT_QUERY_STRING = "?default";

export const getLegalDocumentQueryString = (props?: LegalDocumentPropsType) => {
  let query = "";

  if (props?.careproviderId) {
    query = objectToQueryParamString({
      careprovider_id: props?.careproviderId,
    });
  }
  if (props?.careseekerId) {
    query = objectToQueryParamString({
      careseeker_id: props?.careseekerId,
    });
  }

  return query || DEFAULT_QUERY_STRING;
};

export const useGetLegalDocumentDefaultProps = (): LegalDocumentPropsType => {
  const careproviderId = useLoggedCareproviderId();
  const careseeker = useSelector(selectLoggedCareseeker);
  const { app } = useEnvContext();

  return useMemo(() => {
    if (app === APP_CAREPROVIDER && careproviderId) {
      return {
        careproviderId,
      };
    }
    if (app === APP_CLINIC && careseeker) {
      return {
        careseekerId: careseeker.id,
      };
    }

    return undefined;
  }, [app, careproviderId, careseeker]);
};

const useGetLegalDocsLocalStorageKey = () => {
  const { app } = useEnvContext();
  return `${app}-legal-documents`;
};

export function useGetLegalDocumentsContext(): LegalDocumentsContextType {
  const localStorageKey = useGetLegalDocsLocalStorageKey();
  const defaultProps = useGetLegalDocumentDefaultProps();

  const [getLegalDocuments, progress] = useLazyQuery<{
    legalDocuments: {
      legal_document_map: LegalDocuments;
    };
  }>(GET_LEGAL_DOCUMENTS, {
    fetchPolicy: "network-only",
  });

  const [docs, setDocs] = useState(
    getObjectLocalStorage<LegalDocumentsLocalStorageType>(localStorageKey),
  );

  const setLegalDocs = useCallback(
    (props?: LegalDocumentPropsType) => {
      const expiry = getUnixTime(addDays(new Date(), 1));
      const queryKey = getLegalDocumentQueryString(props);

      getLegalDocuments({ params: queryKey })
        .then(({ data }) => {
          const result = data?.legalDocuments?.legal_document_map;
          if (!result) throw new Error("No data returned");
          let value: LegalDocumentsLocalStorageType = {
            [queryKey]: { value: result, expiry },
          };

          // if the current query is not empty
          // we can append the results with other careseeker / careprovider ids
          // for those with roles for multiple
          if (queryKey !== DEFAULT_QUERY_STRING) {
            value = {
              ...(docs ?? {}),
              ...value,
            };
          }

          setObjectLocalStorage(localStorageKey, value);
          setDocs(value);
        })
        .catch((err) => {
          console.error(
            `error fetching legal documents - ${getErrorMessage(err)}`,
          );
        });
    },
    [getLegalDocuments, setObjectLocalStorage, setDocs],
  );

  useEffect(() => {
    const date = getUnixTime(new Date());
    const query = getLegalDocumentQueryString(defaultProps);
    const storedResult = docs?.[query];

    if (
      storedResult?.value &&
      storedResult?.expiry &&
      storedResult.expiry > date
    ) {
      // if the query is empty, it means we are logged out
      // or in a state where we should store the legal docs without multiple queries
      // i.e. the provider search or b2c apps
      if (query === DEFAULT_QUERY_STRING) {
        const loggedOutValue = {
          [query]: storedResult,
        };
        setObjectLocalStorage(localStorageKey, loggedOutValue);
        setDocs(loggedOutValue);
      }

      return;
    }
    // if we are missing the documents for a specific query, or they have expired
    // fetch them and store in localStorage
    setLegalDocs(defaultProps);
  }, [defaultProps]);

  return {
    getLegalDocs: (props?: {
      careproviderId?: number;
      careseekerId?: number;
    }) => {
      const queryKey = getLegalDocumentQueryString(props ?? defaultProps);
      return docs?.[queryKey]?.value;
    },
    setLegalDocs,
    progress,
  };
}

import { useInterval } from "@recare/core/hooks";
import api from "@recare/core/model/api";
import { Session } from "@recare/core/model/session";
import {
  activateLoggingTracking,
  isTest,
} from "@recare/core/model/utils/featureFlags";
import {
  BaseEventSchema,
  PlatformInfo,
} from "@recare/core/validationSchemas/tracking";
import { format } from "date-fns";
import { useState } from "react";
import { useTracking } from "react-tracking";
import { APP_ACP } from "../../consts";
import { AppType } from "../../types";
import { mockedAnalytics } from "../api/endpoints/analytics/mock";
declare global {
  interface Window {
    batchedEvents: BaseEventSchema[];
  }
}

const { track } = activateLoggingTracking ? api.analytics : mockedAnalytics;

export default function useTrackingProvider({
  account_id,
  app,
  careprovider_id,
  careprovider_name,
  careseeker_name,
  first_name,
  last_name,
  platformInfo,
  session,
}: {
  account_id?: number | null;
  app: AppType;
  careprovider_id?: number | null;
  careprovider_name?: string;
  careseeker_name?: string;
  first_name?: string;
  last_name?: string;
  platformInfo: PlatformInfo;
  session: Session;
}) {
  const [batchedEvents, setBatchedEvents] = useState<BaseEventSchema[]>([]);

  const { Track } = useTracking<BaseEventSchema>(
    {
      user_information: {
        account_id: account_id || null,
        auction_id: null,
        auction_request_id: null,
        careprovider_id: careprovider_id || null,
        careprovider_name: careprovider_name || null,
        careseeker_id: null,
        careseeker_name: careseeker_name || null,
        first_name: first_name || null,
        last_name: last_name || null,
        patient_id: null,
      },
      platform_information: {
        application: platformInfo.application,
        browser_version: platformInfo.browser_version,
        browser: platformInfo.browser,
        build: platformInfo.build,
        device_id: platformInfo.device_id,
        device_screen_height: platformInfo.device_screen_height,
        device_screen_width: platformInfo.device_screen_width,
        env: platformInfo.env,
        hostname: platformInfo.hostname,
        model: platformInfo.model,
        operation_system_version: platformInfo.operation_system_version,
        operation_system: platformInfo.operation_system,
        platform: platformInfo.platform,
        version: platformInfo.version,
      },
      session_information: {
        session_id: session.id,
        session_item: session.trackingEventsCount,
      },
      timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    },
    {
      dispatch(data: BaseEventSchema) {
        if (app === APP_ACP) return;
        if (isTest) {
          window.batchedEvents = [...(window.batchedEvents || []), data];
        } else {
          setBatchedEvents((prev) => [...prev, data]);
        }

        if (!data.system_event) {
          // Monitor why sessions get extended at night.
          const currentDate = new Date();
          if (currentDate.getHours() < 3) {
            console.info(`Off-hours session extension: ${data.name}`);
          }
          session.hasExpired() ? session.reset() : session.moveExpiryAhead();
        }
        // Update session
        session.incrementTrackingEventsCount();
      },
    },
  );

  useInterval(() => {
    const batch = isTest ? window.batchedEvents : batchedEvents;
    if (batch?.length) {
      track(batch);

      const updatedBatchedEvents: BaseEventSchema[] = [];
      if (isTest) {
        window.batchedEvents = updatedBatchedEvents;
      } else {
        setBatchedEvents(updatedBatchedEvents);
      }
    }
  }, 5000);

  return { Track };
}

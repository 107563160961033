import { DispatchAction } from "@recare/core/types";
import { fromJS } from "immutable";

export default function networkState(
  state: { state: string } = { state: "connected" },
  action: DispatchAction,
) {
  switch (action.type) {
    case "NETWORK_CONNECTED":
      return fromJS(state).set("state", "connected").toJS();
    case "NETWORK_CONNECTING":
      return fromJS(state).set("state", "connecting").toJS();
    case "NETWORK_DISCONNECTED":
      return fromJS(state).set("state", "disconnected").toJS();
    default:
      return state;
  }
}

import {
  ApolloLink,
  FetchResult,
  SingleExecutionResult,
  fromPromise,
} from "@apollo/client";
import { hasCrypto } from "@recare/core/model/crypto";
import { ApolloEncryptionContext } from "@recare/core/types";
import decryptPatcher from "./decryptPatcher";
import { getEncryptionDefinition } from "./queryKeys";

type ContextType = AnyObject & { encryptionContext?: ApolloEncryptionContext };

const decryptionPromise = (
  operationName: string,
  response: FetchResult<AnyObject>,
  context: ContextType,
): Promise<SingleExecutionResult<AnyObject>> =>
  new Promise((r) => {
    const { encryptionContext } = context;
    const data = response?.data;
    const definition = getEncryptionDefinition(operationName, "decrypt");

    if (
      data != null &&
      definition &&
      hasCrypto() &&
      encryptionContext?.decrypt === true
    ) {
      decryptPatcher({
        mode: "decrypt",
        data,
        encryptionDefinition: definition,
        encryptionContext,
      }).then((decryptedData) => {
        r({ data: decryptedData });
      });
    } else {
      r(
        response as
          | SingleExecutionResult<AnyObject>
          | PromiseLike<SingleExecutionResult<AnyObject>>,
      );
    }
  });

const decryptionLink = new ApolloLink((operation, forward) => {
  return forward(operation).flatMap((data) =>
    fromPromise(
      decryptionPromise(
        operation.operationName,
        data,
        operation.getContext() as ContextType,
      ),
    ),
  );
});

export default decryptionLink;

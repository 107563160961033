import transport from "@recare/core/model/api/transport";
import { Careseeker } from "@recare/core/types";

export default {
  getByID(id: number): Promise<Careseeker> {
    const queryParams = {
      fields: "billing,providers",
    };

    return transport.get({
      route: `/careseekers/${id}`,
      queryParams,
      options: {
        routeName: "getCareseekerById",
      },
    });
  },
};

import { Paper, useTheme } from "@mui/material";
import {
  APP_B2C,
  APP_PROVIDER_SEARCH,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
} from "@recare/core/consts";
import authApi from "@recare/core/model/api/endpoints/auth";
import { ENV_PRODUCTION } from "@recare/core/model/config";
import { getErrorMessage } from "@recare/core/model/utils/errors";
import { isCI } from "@recare/core/model/utils/featureFlags";
import { getQueryVariable } from "@recare/core/model/utils/urls";
import { AppType, Env, QueryProgress } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { useEnvContext } from "context/EnvContext";
import RSButton from "ds/components/RSButton";
import { TextInputField } from "ds/components/TextInputField";
import { VerticalLayout } from "ds/materials/layouts";
import { padding, sizing } from "ds/materials/metrics";
import { Subheading } from "ds/materials/typography";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { ReactNode, useEffect, useState } from "react";

const getLocalStorageKey = (app: AppType) => `test-env-password-${app}`;

const useLoggedPasswordState = (): [string, (newPassword: string) => void] => {
  const { app } = useEnvContext();
  const localStorageKey = getLocalStorageKey(app);
  const [password, setPassword] = useState(() => {
    return localStorage.getItem(localStorageKey) ?? "";
  });

  return [
    password,
    (newPassword: string) => {
      localStorage.setItem(localStorageKey, newPassword);
      setPassword(newPassword);
    },
  ];
};

const getTitle = (app: AppType, translations: Translations): string => {
  switch (app) {
    case APP_B2C:
      return translations.bToC.preprodLogin;
    case APP_PROVIDER_SEARCH:
      return translations.providersearch.preprodLogin;
    default:
      return translations.login.login;
  }
};

const TestEnvLoginForm = ({
  queryProgress,
  setStoredPassword,
}: {
  queryProgress: QueryProgress;
  setStoredPassword: (newPassword: string) => void;
}) => {
  const [password, setPassword] = useState("");
  const translations = useTranslations();
  const theme = useTheme();
  const { app, env, setEnv } = useEnvContext();

  useEffect(() => {
    const envParam = getQueryVariable(window.location.search, "env");
    if (envParam && env !== envParam) setEnv(envParam as Env);
  }, [window.location.search]);

  return (
    <VerticalLayout
      height="100vh"
      width="100%"
      style={{
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.grey[200],
      }}
    >
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: padding(2, 3),
          rowGap: sizing(1),
        }}
      >
        <Subheading>{getTitle(app, translations)}</Subheading>
        <TextInputField
          placeholder={translations.newOnboarding.password.caption}
          textInputType="password"
          value={password}
          onChange={setPassword}
        />
        <RSButton
          color="primary"
          onClick={() => {
            if (!password) return;
            setStoredPassword(password);
          }}
          variant="contained"
          loading={queryProgress}
          id="password-test-env"
        >
          {translations.actions.submit}
        </RSButton>
      </Paper>
    </VerticalLayout>
  );
};

const BLOCKED_APPS = [APP_PROVIDER_SEARCH, APP_B2C] as AppType[];

const TestEnvLogin = ({ children }: { children: ReactNode }) => {
  const translations = useTranslations();
  const notify = useNotification();

  const [isLogged, setIsLogged] = useState(false);
  const [storedPassword, setStoredPassword] = useLoggedPasswordState();
  const [checkingStoredPassword, setCheckingStoredPassword] = useState(true);
  const [queryProgress, setQueryProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  useEffect(() => {
    const checkPassword = async () => {
      try {
        if (!storedPassword) {
          return;
        }
        setQueryProgress(QUERY_PROGRESS_PENDING);
        await authApi.testEnvPasswordCheck({
          password: storedPassword,
        });
        setIsLogged(true);
      } catch (err) {
        setIsLogged(false);
        setStoredPassword("");

        console.error("error in test env login:", getErrorMessage(err));
        notify({ message: translations.login.failedAlert.title });
      } finally {
        setCheckingStoredPassword(false);
        setQueryProgress(QUERY_PROGRESS_NOT_STARTED);
      }
    };
    checkPassword();
  }, [storedPassword]);

  if (checkingStoredPassword) {
    return null;
  }

  if (!isLogged) {
    return (
      <TestEnvLoginForm
        queryProgress={queryProgress}
        setStoredPassword={setStoredPassword}
      />
    );
  }

  return <>{children}</>;
};

export const TestEnvLoginProvider = ({ children }: { children: ReactNode }) => {
  const { app, env } = useEnvContext();

  const isDev = window?.location?.origin === "http://localhost:9090";

  if (env === ENV_PRODUCTION || !BLOCKED_APPS.includes(app) || isCI || isDev) {
    return <>{children}</>;
  }

  return <TestEnvLogin>{children}</TestEnvLogin>;
};

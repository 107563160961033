import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
} from "@recare/core/consts";
import { mockLegalDocuments } from "@recare/core/mocks";
import { LegalDocuments } from "@recare/core/types";
import { ReactNode, createContext, useContext } from "react";
import {
  LegalDocumentsContextType,
  useGetLegalDocumentsContext,
} from "./utils";

const LegalDocumentsContext = createContext<LegalDocumentsContextType>({
  getLegalDocs: () => {
    return undefined;
  },
  setLegalDocs: () => {},
  progress: QUERY_PROGRESS_PENDING,
});

export const useLegalDocumentsContext = () => useContext(LegalDocumentsContext);

export function LegalDocumentsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const context = useGetLegalDocumentsContext();

  return (
    <LegalDocumentsContext.Provider value={context}>
      {children}
    </LegalDocumentsContext.Provider>
  );
}

export function MockLegalDocumentsContextProvider({
  children,
  legalDocuments,
}: {
  children: ReactNode | null;
  legalDocuments?: Partial<LegalDocuments>;
}) {
  const getLegalDocs = () => mockLegalDocuments(legalDocuments);

  return (
    <LegalDocumentsContext.Provider
      value={{
        getLegalDocs,
        setLegalDocs: () => {},
        progress: QUERY_PROGRESS_NOT_STARTED,
      }}
    >
      {children}
    </LegalDocumentsContext.Provider>
  );
}

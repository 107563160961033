/**
 * This file interacts with a library that can have access and potentially expose our document data to third party vendors.
 * Have in mind any changes to existing interactions need to be carefully reviewed,
 * since we have sensitive patient data rendered in varied nodes throughout the pages.
 */
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_REHABILITATION,
} from "@recare/core/consts";
import {
  activateBeamer,
  activateRealUserMonitoring,
} from "@recare/core/model/utils/featureFlags";
import {
  Account,
  AnyObject,
  AppType,
  Careprovider,
  Careseeker,
} from "@recare/core/types";
import { useGetAccount } from "apollo/hooks/queries";
import { usePrint } from "dsl/atoms/Contexts";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useLoggedCareprovider,
  useLoggedCareseeker,
} from "reduxentities/selectors/hooks";
import { selectLoggedInAccountId } from "reduxentities/selectors/index";
import { useMedia } from "./ResponsiveMedia";

declare const window: any;

export function accountDetails(account: Account | null | undefined):
  | AnyObject
  | {
      account_id: string;
      email?: string;
      first_name: string;
      last_name: string;
      name: string;
      phone?: string;
      user_id: string;
    } {
  if (!account) return {};
  return {
    name: `${account.first_name || ""} ${account.last_name || ""}`,
    first_name: account.first_name,
    last_name: account.last_name,
    user_id: account.id,
    account_id: account.id,
  };
}

function updateCrispAccountData(account: Account | null | undefined) {
  if (!account) return;
  window.$crisp.push(["set", "user:email", [account.email]]);

  if (account.phone) window.$crisp.push(["set", "user:phone", [account.phone]]);

  window.$crisp.push([
    "set",
    "user:nickname",
    [`${account.first_name} ${account.last_name}`],
  ]);
}

function updateBeamer(data: AnyObject) {
  if (!window.Beamer) return;
  if (!activateBeamer()) return;

  window.Beamer.init();
  window.Beamer.update(data);
}

export function updateProvider(
  account: Account | null | undefined,
  careprovider: Careprovider,
) {
  const filters = ["receiver_rights"];

  switch (careprovider.patient_type) {
    case SEARCH_TYPE_HOSPITAL:
      filters.push("receiver_hospital");
      break;
    case SEARCH_TYPE_CARE:
      filters.push("receiver_care");
      break;
    case SEARCH_TYPE_REHABILITATION:
      filters.push("receiver_rehabilitation");
      break;
  }

  updateBeamer({
    filter: filters.join(","),
    language: careprovider.address?.country,
  });

  if (window.$crisp) {
    updateCrispAccountData(account);

    window.$crisp.push(["set", "session:segments", [["app:receiver"]]]);

    window.$crisp.push([
      "set",
      "user:company",
      [
        careprovider.name,
        {
          geolocation: [
            careprovider.address?.country,
            careprovider.address?.city,
          ],
        },
      ],
    ]);
  }

  setDatadogUser(account?.id);
  datadogLogs.setGlobalContextProperty("careprovider_id", careprovider.id);
}

function setDatadogUser(accountId?: number) {
  const user = { id: accountId?.toString() ?? "nouser" };
  if (activateRealUserMonitoring) {
    datadogRum.setUser(user);
  }
  datadogLogs.setUser(user);

  // Probably not needed
  datadogLogs.setGlobalContextProperty("user_id", accountId);
}

export function updateCareseeker(
  account: Account | null | undefined,
  careseeker: Careseeker,
) {
  const filters = ["sender_rights"];

  if (careseeker.patient_types?.includes(SEARCH_TYPE_HOSPITAL))
    filters.push("sender_hospital");

  if (careseeker.patient_types?.includes(SEARCH_TYPE_CARE))
    filters.push("sender_care");

  if (careseeker.patient_types?.includes(SEARCH_TYPE_REHABILITATION))
    filters.push("sender_rehabilitation");

  if (window.$crisp) {
    updateCrispAccountData(account);

    window.$crisp.push(["set", "session:segments", [["app:sender"]]]);

    window.$crisp.push([
      "set",
      "user:company",
      [
        careseeker.name,
        {
          geolocation: [careseeker.address?.country, careseeker.address?.city],
        },
      ],
    ]);
  }

  updateBeamer({
    filter: filters.join(","),
    language: careseeker.address?.country,
  });

  setDatadogUser(account?.id);
  datadogLogs.setGlobalContextProperty("careseeker_id", careseeker.id);
}

function Intercom({
  account,
  app,
  careprovider,
  careseeker,
}: IntercomEnhancedProps) {
  const { isTablet } = useMedia();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (app !== APP_CAREPROVIDER) return;
    if (!careprovider?.name) return;

    updateProvider(account, careprovider);
  }, [account?.id, careprovider?.name]);

  useEffect(() => {
    if (app !== APP_CLINIC) return;
    if (!account) return;
    if (!careseeker?.name) return;

    updateCareseeker(account, careseeker);
  }, [account?.id, careseeker?.name]);

  return (
    <PlaceholderDiv
      isMobile={isMobile}
      setIsMobile={setIsMobile}
      queryIsMobile={isTablet}
    />
  );
}

function PlaceholderDiv({
  isMobile,
  queryIsMobile,
  setIsMobile,
}: {
  isMobile: boolean;
  queryIsMobile: boolean;
  setIsMobile: (arg: boolean) => void;
}) {
  useEffect(() => {
    if (queryIsMobile != isMobile) setIsMobile(queryIsMobile);
  }, [queryIsMobile, isMobile]);
  return <div />;
}

type IntercomProps = {
  app: AppType;
};

type IntercomEnhancedProps = IntercomProps & {
  account?: Account | undefined;
  careprovider?: Careprovider | null | undefined;
  careseeker?: Careseeker | null | undefined;
};

function IntercomWrapperPresenter({
  accountId,
  app,
}: IntercomProps & { accountId: number }) {
  const [, account] = useGetAccount(accountId);
  const careseeker = useLoggedCareseeker();
  const careprovider = useLoggedCareprovider();

  return (
    <Intercom
      app={app}
      account={account}
      careseeker={careseeker}
      careprovider={careprovider}
    />
  );
}

export default function IntercomWrapper({ app }: IntercomProps) {
  const print = usePrint();
  const accountId = useSelector(selectLoggedInAccountId);

  if (print) return null;

  const loginPage = window.location?.pathname === "/auth";
  const newOnboardingPage = window.location?.pathname.includes("newonboarding");

  if (newOnboardingPage) return null;
  if (loginPage || !accountId) return <Intercom app={app} />;

  return <IntercomWrapperPresenter app={app} accountId={accountId} />;
}

import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip";
import { CSSTextAlign } from "@recare/core/types";
import { ReactNode } from "react";

export default function Tooltip({
  children,
  enterDelay,
  isHoverable = false,
  open,
  placement,
  title,
  tooltipTextAlign = "left",
}: Omit<TooltipProps, "title"> & {
  children?: ReactNode;
  isHoverable?: boolean;
  title?: TooltipProps["title"] | string | null | undefined;
  tooltipTextAlign?: CSSTextAlign;
}) {
  if (!title) return <>{children}</>;

  return (
    <MuiTooltip
      title={title}
      placement={placement}
      enterDelay={enterDelay}
      slotProps={{
        tooltip: {
          style: {
            pointerEvents: "none",
          },
        },
      }}
      PopperProps={{
        style: {
          pointerEvents: isHoverable ? "auto" : "none",
          textAlign: tooltipTextAlign,
        },
      }}
      open={open}
      className="tooltip"
    >
      {children}
    </MuiTooltip>
  );
}

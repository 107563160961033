/**
 * This file interacts with a library that can have access and potentially expose our document data to third party vendors.
 * Have in mind any changes to existing interactions need to be carefully reviewed,
 * since we have sensitive patient data rendered in varied nodes throughout the pages.
 */
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import Config from "@recare/core/model/config";
import {
  activateLoggingTracking,
  activateRealUserMonitoring,
} from "@recare/core/model/utils/featureFlags";
import { AnyObject, ToType } from "@recare/core/types";
let datadogInit = false;

function startDatadog(appName: string) {
  if (datadogLogs) {
    datadogLogs.init({
      clientToken: "pub83c3331b43cb4994d28cb053017424ba",
      site: "datadoghq.eu",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: Config.environment,
      version: process.env.BUILD_VERSION,
      forwardConsoleLogs: [/*"debug",*/ "error", "info" /*"log"*/, "warn"],
    });

    datadogLogs.setGlobalContext({
      app_version: Config.version,
      environment: Config.environment,
      app_name: appName,
      service: "frontend",
      release: `${appName} - ${process.env.BUILD_VERSION}`,
    });
  }
}

export interface Logger {
  debug(message: string, ...tags: Array<ToType>): void;
  error(message: string, ...tags: Array<ToType>): void;
  info(message: string, ...tags: Array<ToType>): void;
  warn(message: string, ...tags: Array<ToType>): void;
}

const originalConsole = console;

export const ConsoleLogger: Logger = {
  debug(message: string, tags: AnyObject) {
    if ("log" in originalConsole) {
      originalConsole.info(`${message} ${tags ? JSON.stringify(tags) : ""}`);
    }
  },
  error(message: string, tags: AnyObject) {
    if ("log" in originalConsole) {
      originalConsole.error(`${message} ${tags ? JSON.stringify(tags) : ""}`);
    }
  },
  info(message: string, tags: AnyObject) {
    if ("log" in originalConsole) {
      originalConsole.info(`${message} ${tags ? JSON.stringify(tags) : ""}`);
    }
  },
  warn(message: string, tags: AnyObject) {
    if ("log" in originalConsole) {
      originalConsole.warn(`${message} ${tags ? JSON.stringify(tags) : ""}`);
    }
  },
};

export class DatadogLogger {
  consoleLogger: Logger;

  constructor(appName: string) {
    this.consoleLogger = ConsoleLogger;

    if (!datadogInit) {
      startDatadog(appName);
      datadogInit = true;
      console.log(
        "Datadog Logger is configured for env",
        Config.environment,
        Config.version,
      );
    }
  }

  error(message: string, ...tags: Array<ToType>) {
    this.consoleLogger.error(message, tags);
  }

  info(message: string, ...tags: Array<ToType>) {
    this.consoleLogger.info(message, tags);
  }

  debug(message: string, ...tags: Array<ToType>) {
    this.consoleLogger.debug(message, tags);
  }
}

export const configureLogging = (appName: string) => {
  const logger = activateLoggingTracking
    ? new DatadogLogger(appName)
    : ConsoleLogger;

  // eslint-disable-next-line no-var
  var console = {
    log: originalConsole.log,
    error: (error: unknown, context: AnyObject) => {
      if (activateRealUserMonitoring) {
        datadogRum.addError(error, context);
      }
      return originalConsole.error(error, context);
    },
    info: (message: string, context: AnyObject) =>
      logger.info(message, context),
    debug: (message: string, context: AnyObject) =>
      logger.debug(message, context),
    warn: (message: string, context: AnyObject) =>
      logger.info(message, context),
    assert: originalConsole.assert,
    clear: originalConsole.clear,
    count: originalConsole.count,
    dir: originalConsole.dir,
    dirxml: originalConsole.dirxml,
    group: originalConsole.group,
    groupCollapsed: originalConsole.groupCollapsed,
    groupEnd: originalConsole.groupEnd,
    profile: originalConsole.profile,
    profileEnd: originalConsole.profileEnd,
    table: originalConsole.table,
    time: originalConsole.time,
    timeEnd: originalConsole.timeEnd,
    timeStamp: originalConsole.timeStamp,
  };

  if (window) {
    // @ts-ignore
    window.console = console;
  }

  return logger;
};

export type LoggingType = {
  debug(eventName: string, context?: any): void;
  error(eventName: string, context?: any): void;
  info(eventName: string, context?: any): void;
};

import transport from "@recare/core/model/api/transport";
import ErrorPage from "dsl/ecosystems/ErrorPage";
import { ReactNode, useEffect, useState } from "react";

type MaintenanceStatus = {
  id: number;
  in_progress: boolean;
  planned_at: number;
  planned_end_date: number;
  reason: string;
};

export default function MaintenanceBoundary({
  children,
}: {
  children: ReactNode;
}) {
  const [maintenanceStatus, setMaintenanceStatus] = useState<
    MaintenanceStatus | null | undefined
  >();

  useEffect(() => {
    transport
      .get<MaintenanceStatus>({
        route: `/maintenance`,
      })
      .then(setMaintenanceStatus)
      .catch(() => {
        setMaintenanceStatus(null);
      });
  }, []);

  if (maintenanceStatus === undefined) return null;

  if (maintenanceStatus?.in_progress)
    return (
      <ErrorPage
        getErrorLabel={(translations) =>
          translations.careseeker.maintenancePAge
        }
        pageName="Maintenance"
        reason={maintenanceStatus.reason}
      />
    );

  return <>{children}</>;
}

import transport from "@recare/core/model/api/transport";
import { SessionKey } from "@recare/core/types";

export default {
  getRequestEvents(requestId: number): Promise<any> {
    return transport.get({
      route: `/events?reverse&auction_request_id=${requestId}`,
    });
  },
  getRequestCounts(careproviderId?: number): Promise<any> {
    return transport.get({
      route: `/careproviders/requestscount/${careproviderId}`,
    });
  },
  updateSessionKeys(
    requestId: number,
    sessionKeys: Array<SessionKey>,
  ): Promise<any> {
    return transport.put({
      route: `/crypto/sessions/${requestId}`,
      body: sessionKeys,
    });
  },
};

import { APP_CAREPROVIDER, APP_CLINIC } from "@recare/core/consts";
import authApi from "@recare/core/model/api/endpoints/auth";
import { BaseEventSchema } from "@recare/core/validationSchemas/tracking";
import { useEnvContext } from "context/EnvContext";
import { SpinnerPage, SpinnerPageContainer } from "ds/components/Spinner";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { useOnLogout } from "reduxentities/actions";
import { State } from "reduxentities/selectors";
import { accountDetails } from "../Intercom";

export default function HealthCheck({
  children,
}: {
  children: React.ReactNode;
}) {
  const { app } = useEnvContext();
  const [valid, setIsValid] = useState(false);
  const auth = useSelector((state: State) => state?.auth);
  const { first_name, last_name } = accountDetails(
    auth?.identification?.account,
  );
  const onLogout = useOnLogout();

  const { Track } = useTracking<DeepPartial<BaseEventSchema>>(
    {
      user_information: {
        careprovider_id:
          (app === APP_CAREPROVIDER && auth?.careprovider) || null,
        careprovider_name:
          (app === APP_CAREPROVIDER && auth?.careprovider_name) || null,
        careseeker_id: (app === APP_CLINIC && auth?.careseeker) || null,
        careseeker_name: (app === APP_CLINIC && auth?.careseeker_name) || null,
        first_name: first_name || null,
        last_name: last_name || null,
      },
    },
    { dispatchOnMount: false },
  );
  const { config } = useEnvContext();

  useEffect(() => {
    async function validateSession() {
      if (auth?.credentials?.token) {
        try {
          await authApi.checkToken();
          console.log("%c token check [ valid ]", "color: #005a9c");
          setIsValid(true);
        } catch (_) {
          console.log("%c token check [ invalid ]", "color: #005a9c");
          onLogout({ tokenExpired: true });
        }
      } else {
        setIsValid(true);
      }
    }

    if (!valid) {
      validateSession();
    }
  }, [auth, valid, config]);

  if (!valid)
    return (
      <Track>
        <SpinnerPageContainer>
          <SpinnerPage id="health-check" />
        </SpinnerPageContainer>
      </Track>
    );
  return <Track>{children}</Track>;
}

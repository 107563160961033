import transport from "@recare/core/model/api/transport";
import {
  isCI,
  isProd,
  isStoryshots,
} from "@recare/core/model/utils/featureFlags";
import { BaseEventSchema } from "@recare/core/validationSchemas/tracking";

const activateValidation = !isProd && !isCI && !isStoryshots;

let validateTrackingEventData: (props: { events: BaseEventSchema[] }) => void;

if (activateValidation) {
  import("../../../../validationSchemas/tracking/validator").then((module) => {
    validateTrackingEventData = module.default;
  });
}

export default {
  getQuicksightLink(
    id: number,
    isTestQuickSightDashboard?: boolean,
  ): Promise<{ url: string }> {
    return transport.get({
      route: `/analytics/careseeker/${id}/quicksight${
        isTestQuickSightDashboard ? "?test_dashboard" : ""
      }`,
      options: {
        routeName: "getReport",
      },
    });
  },
  track: async (
    event: BaseEventSchema | BaseEventSchema[],
  ): Promise<null | undefined> => {
    const events = Array.isArray(event) ? event : [event];
    try {
      if (activateValidation) {
        validateTrackingEventData({ events });
      }

      return await transport.post({
        route: `/frontendevents`,
        body: events.map(
          ({
            name,
            page_information,
            platform_information,
            session_information,
            timestamp,
            user_information,
            ...overwritable
          }) => ({
            ...overwritable,
            ...page_information,
            ...platform_information,
            ...session_information,
            ...user_information,
            name,
            timestamp,
          }),
        ),
      });
    } catch (err) {
      console.error(err);
    }
  },
};

import { activateEnvSwitch } from "@recare/core/model/utils/featureFlags";
import { getQueryVariable } from "@recare/core/model/utils/urls";
import { Env } from "@recare/core/types";
import { useEnvContext } from "context/EnvContext";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function RedirectRoot({ to }: { to: string }) {
  const location = useLocation();
  const { env, setEnv } = useEnvContext();
  useEffect(() => {
    if (activateEnvSwitch) {
      const envParam = getQueryVariable(location.search, "env");
      if (envParam && env !== envParam) setEnv(envParam as Env);
    }
  }, []);

  return <Navigate to={{ ...location, pathname: to }} replace />;
}

import { DispatchAction } from "@recare/core/types";
import { fromJS } from "immutable";

export default function appState(
  state: {
    cacheLoaded: boolean;
    lastRequestFailed: number | null;
  } = {
    cacheLoaded: false,
    lastRequestFailed: null,
  },
  action: DispatchAction,
) {
  switch (action.type) {
    case "persist/REHYDRATE":
      return fromJS(state).set("cacheLoaded", true).toJS();
    case "REQUEST_STATE_CHANGED":
      if (action.payload.state == "ERROR")
        return fromJS(state)
          .set("lastRequestFailed", {
            id: action.payload.requestID,
            details: action.payload.error,
            date: Date.now(),
          })
          .toJS();
      return state;
    case "QUERY_FAILED":
      return fromJS(state)
        .set("lastRequestFailed", {
          id: action.payload.query.request.hash,
          details: action.payload.errors,
          date: Date.now(),
        })
        .toJS();
    default:
      return state;
  }
}

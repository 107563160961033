import { ACCOUNT_TYPE_STAFF } from "@recare/core/consts";
import Config, { ENV_PRODUCTION } from "@recare/core/model/config";
import { usePrint } from "dsl/atoms/Contexts";
import lazy from "dsl/atoms/retry";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { State } from "reduxentities/selectors/index";
const LanguageFab = lazy(() => import("./Fab"));

export default function LazyLanguageFab() {
  const print = usePrint();
  const loggedAccountType = useSelector(
    (s: State) => s.auth?.identification?.account?.account_type,
  );

  const isStaff = loggedAccountType === ACCOUNT_TYPE_STAFF;

  if (print || (Config.environment === ENV_PRODUCTION && !isStaff)) return null;

  return (
    <Suspense fallback={<div />}>
      <LanguageFab />
    </Suspense>
  );
}

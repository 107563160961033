import { getFirstProviderRole } from "@recare/core/model/careproviders";
import {
  Careprovider,
  CareproviderRoles,
  Careseeker,
  DispatchAction,
} from "@recare/core/types";
import { getUnixTime, subDays } from "date-fns";
import {
  StateType,
  findMatchingCareproviderFromState,
  findMatchingCareseekerFromState,
  getFirstSeekerRole,
  setAuthState,
  setCareprovider,
  setCareseeker,
  setCareseekerRoles,
  setCountry,
  setProviderSearchCareseeker,
} from "./authUtils";

const yesterday = getUnixTime(subDays(new Date(), 1));

export default function auth(
  state: StateType = { credentials: { token: null }, identification: null },
  action: DispatchAction,
) {
  switch (action.type) {
    case "TOKEN_CHANGED": {
      //Auth State
      let updatedState = setAuthState(state, action);

      //Careprovider
      const existingCareprovider = findMatchingCareproviderFromState(
        action?.payload?.identification?.careprovider_roles,
        state,
      );
      const firstProviderInRoles = getFirstProviderRole(
        action.payload.identification.careprovider_roles,
      );
      const careproviderFromRolePayload = firstProviderInRoles?.careprovider;

      const careproviderId =
        action.payload.careproviderToLog ||
        existingCareprovider ||
        careproviderFromRolePayload?.id;

      const careprovider: Careprovider | undefined =
        action?.payload?.identification?.careprovider_roles?.find(
          (role: CareproviderRoles) => role?.id === careproviderId,
        )?.careprovider;

      updatedState = setCareprovider({
        careproviderId,
        careproviderName: careprovider?.name,
        state: updatedState,
      });

      //Careseeker
      const existingCareseeker = findMatchingCareseekerFromState(
        action?.payload?.identification?.careseeker_roles,
        state,
      );

      const firstSeekerInRoles = getFirstSeekerRole(
        action.payload.identification.careseeker_roles,
      )?.careseeker;
      const careseekerId = existingCareseeker || firstSeekerInRoles?.id;

      const careseeker: Careseeker | undefined =
        action.payload.identification.careseeker_roles?.find(
          (role: CareproviderRoles) => role?.id && role?.id === careseekerId,
        )?.careseeker;

      updatedState = setCareseeker(
        careseekerId,
        careseeker?.name ?? "",
        updatedState,
      );
      //Country
      return setCountry(
        careseeker?.address?.country ?? careprovider?.address?.country,
        updatedState,
      );
    }
    case "CARESEEKER_CHANGED": {
      const updatedState = setCountry(action.payload?.country, state);
      return setCareseeker(
        action.payload.careseeker_id,
        action.payload?.careseeker_name,
        updatedState,
      );
    }
    case "CAREPROVIDER_CHANGED": {
      const updatedState = setCountry(action.payload?.country, state);
      return setCareprovider({
        careproviderId: action.payload.careproviderId,
        state: updatedState,
      });
    }
    case "SET_CARESEEKER": {
      const updatedRoles = setCareseekerRoles(state, action);
      const updatedCareseeker = setProviderSearchCareseeker(
        updatedRoles,
        action,
      );
      return updatedCareseeker;
    }
    case "COUNTRY_CHANGED": {
      return setCountry(action.payload?.country, state);
    }
    case "LOGGED_OUT":
      return {
        ...state,
        careprovider: undefined,
        careprovider_name: undefined,
        careseeker: undefined,
        careseeker_name: undefined,
        credentials: { token: null },
        active_roles: null,
        identification: null,
      };
    case "_TEST_EXPIRE_SESSION":
      return {
        ...state,
        credentials: {
          ...state.credentials,
          expiration: yesterday,
        },
      };
    default:
      return state;
  }
}

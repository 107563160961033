import api from "@recare/core/model/api";
import {
  setAuthAccessor,
  setTokenAccessor,
  setTokenExpiration,
} from "@recare/core/model/api/transport";
import { getTopLevelDomain } from "@recare/core/model/utils/urls";
import { DispatchAction } from "@recare/core/types";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { rootPersistConfig as config } from "reduxentities/reducers/persistReducer";
import getReducers from "../reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export function setupStore(persist: boolean) {
  // Define middlewares
  const Middlewares = [thunk];

  const combinedReducers = persist
    ? persistCombineReducers<AnyObject, DispatchAction>(
        config,
        getReducers(true),
      )
    : combineReducers<AnyObject>(getReducers());

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Create Store
  const store = createStore(
    combinedReducers as any,
    {
      settings: {
        // LANG is statically defined in webpack for test envs
        // Top level domain should be used in preprod and prod envs
        language: process.env.LANG || getTopLevelDomain().toLowerCase(),
      },
    },
    composeEnhancers(applyMiddleware(...Middlewares)),
  );

  let persistor;
  if (persist) {
    persistor = persistStore(store, null);
  }

  const setTokenFn = () => {
    return (store.getState() as AnyObject).auth.credentials.token;
  };

  api.setTokenAccessor(setTokenFn);
  api.setAuthAccessor(
    () => (store.getState() as AnyObject).auth.identification,
  );
  api.setTokenExpiration(
    () => (store.getState() as AnyObject).auth.credentials.expiration,
  );

  setTokenAccessor(setTokenFn);
  setAuthAccessor(() => (store.getState() as AnyObject).auth.identification);
  setTokenExpiration(
    () => (store.getState() as AnyObject).auth.credentials.expiration,
  );

  return { store, persistor };
}

import React from "react";
import { NEVER, Observable, Subscription } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import {
  ObservableValue,
  StateDispatcherContext,
  formElementContext,
} from "./Context";
import {
  NeverObservable,
  getAssignedObs,
  getCompleteCurrentStatePath,
  getCurrentStatePath,
  getHumanReadableIdentifier,
} from "./FormElementHelpers";
import {
  INITIAL_CHANGE_EVENT_TYPE,
  VALIDATION_FAILED_EVENT_TYPE,
  VALUE_CHANGE_EVENT_TYPE,
} from "./FormEvents";
import { getValue, hideComponent } from "./StateValueHelpers";
import { Validation } from "./ValidationHelpers";
import { FieldValidation } from "./types";

type FormElementContext = {
  completeStatePath: string | null;
  statePath: string | null;
};

export type InjectedFormElementProps = {
  elementName: string | undefined;
  getValue: (() => any) | undefined;
  hideComponent: any | null;
  onChange: (newValue: any, statePath?: string) => void;
  rootValueChangeObs: StateDispatcherContext["rootValueChangeObs"];
  statePath: string;
  validation: FieldValidation | undefined;
  value: any;
  valueChangeObs: Observable<ObservableValue>;
};

export type FormElementContainerProps = {
  children: (props: InjectedFormElementProps) => JSX.Element;
  debug?: boolean;
  elementName: string | undefined;
  flatModel?: boolean;
  formElementContext: FormElementContext;
  onChange?: (value: any, statePath: string, validation: any) => void;
  root?: boolean;
  sideMutation?: (newValue: any, mutateElement: AnyFunction) => void;
  stateDispatcherContext: StateDispatcherContext;
  validation?: FieldValidation;
  value?: any;
  valueChangeObs?: Observable<ObservableValue>;
};

type FormElementContainerState = {
  hideComponent: null;
  validation?: FieldValidation;
  value: any;
};

export class FormElementContainer extends React.Component<
  FormElementContainerProps,
  FormElementContainerState
> {
  getValue?: () => any;
  onChange: (newValue: any, statePath?: string) => void;
  parentValueChangeObs: Observable<ObservableValue>;
  statePath?: string;
  subscription?: Subscription;
  validation?: FieldValidation;
  value?: any;
  valueChangeObs: Observable<ObservableValue>;

  constructor(props: FormElementContainerProps) {
    super(props);
    this.state = {
      value: null,
      hideComponent: null, // @TODO: this value is never changed. What's the purpose?
    };

    this.getValue = props.root ? () => this.value : undefined;

    const statePath = getCurrentStatePath(
      props.elementName,
      props.formElementContext.statePath,
    );

    this.onChange =
      props.onChange != null
        ? (newValue: any, sp?: string) => {
            props.onChange?.(newValue, sp !== undefined ? sp : statePath, true);
          }
        : (newValue: any, sp?: string) => {
            if (
              props.stateDispatcherContext.onStateChange &&
              props.sideMutation !== undefined
            )
              props.sideMutation(
                newValue,
                props.stateDispatcherContext.onStateChange,
              );

            props.stateDispatcherContext.onStateChange?.(
              newValue,
              sp !== undefined ? sp : statePath,
              true,
            );
          };

    this.parentValueChangeObs = NEVER;
    this.valueChangeObs = NEVER;
    this.configureObs(props);
  }

  configureObs(props: FormElementContainerProps) {
    this.parentValueChangeObs = getAssignedObs(props);
    if (this.parentValueChangeObs === NeverObservable && props.debug)
      console.warn(
        `${getHumanReadableIdentifier(
          props.elementName,
          props.formElementContext.statePath,
        )} hasn't received any valueChangeObs neither as props. Maybe you have forgotten the FormController / StateDispatcher duo`,
      );
    this.valueChangeObs = this.parentValueChangeObs.pipe(
      tap((e) => {
        if (props.debug) {
          const path = getCompleteCurrentStatePath(
            props,
            props.formElementContext.completeStatePath,
            e.value,
          );
          if (path !== "" && e.type === VALUE_CHANGE_EVENT_TYPE) {
            console.log(
              "FILTERING",
              path,
              "FOR STATEPATH",
              e.statePath,
              "PROPS",
              props,
            );
          }
        }
      }),
      filter((e) => {
        const path = getCompleteCurrentStatePath(
          props,
          props.formElementContext.completeStatePath,
          e.value,
        );
        return !!(
          e.type === INITIAL_CHANGE_EVENT_TYPE ||
          e.type === VALIDATION_FAILED_EVENT_TYPE ||
          path.startsWith(e.statePath as string) ||
          (props.root && (e.statePath as string).startsWith(path))
        );
      }),
      tap((e) => {
        if (props.debug) {
          const path = getCompleteCurrentStatePath(
            props,
            props.formElementContext.completeStatePath,
            e.value,
          );
          if (path !== "" && e.type === VALUE_CHANGE_EVENT_TYPE) {
            console.log("REFRESHING", path, "FOR EVENT", e);
          }
        }
      }),
      map((e) => {
        const statePath = getCurrentStatePath(
          props.elementName,
          props.formElementContext.statePath,
          e.value,
        );

        return Object.assign({}, e, {
          value: getValue(e.value, statePath),
          validation:
            e.validation && e.validation instanceof Validation
              ? e.validation.getNestedValidation(statePath)
              : e.validation,
        });
      }),
      tap((e) => {
        if (props.debug) {
          console.log("FOUND VALUE:", e);
        }
      }),
    );
  }

  connectToFormController(props: FormElementContainerProps) {
    if (this.valueChangeObs) {
      this.disconnectFromFormController();
      this.subscription = this.valueChangeObs.subscribe((e) => {
        if (props.debug) {
          console.log(
            "Setting value",
            e,
            `in ${props.root ? "instance" : "state"} mode`,
          );
        }
        if (e.value === undefined && !props.elementName?.includes(".")) {
          console.log(
            `%c No input value sent to form element with ${getHumanReadableIdentifier(
              props.elementName,
              props.formElementContext.statePath,
            )}. It is likely a modelDefinition issue`,
            "color: red; font-weight: bold",
          );
        }
        if (props.root) {
          this.value = e.value;
          this.validation = e.validation;
        } else {
          // const statePath = getCompleteCurrentStatePath(
          //   props,
          //   props.formElementContext.completeStatePath,
          //   e.value,
          // );

          this.setState({
            value: e.value,
            validation: e.validation,
          });
        }
      });
    }
  }

  disconnectFromFormController() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  componentDidMount() {
    this.connectToFormController(this.props);
  }

  componentDidUpdate() {
    if (this.parentValueChangeObs !== getAssignedObs(this.props)) {
      console.log(
        `Reconnecting ${getHumanReadableIdentifier(
          this.props.elementName,
          this.props.formElementContext.statePath,
        )}`,
      );
      this.configureObs(this.props);
      this.connectToFormController(this.props);
    }
  }

  componentWillUnmount() {
    this.disconnectFromFormController();
  }

  render() {
    const value =
      this.state.value == undefined && this.props.value !== undefined
        ? this.props.value
        : this.state.value;
    const validation =
      this.state.validation == undefined && this.props.validation !== undefined
        ? this.props.validation
        : this.state.validation;
    const statePath = getCurrentStatePath(
      this.props.elementName,
      this.props.formElementContext.statePath,
    );

    if (hideComponent(this.props.stateDispatcherContext?.whitelist, statePath))
      return null;

    return (
      <formElementContext.Provider
        value={{
          statePath: this.props.root ? "" : statePath,
          completeStatePath: getCompleteCurrentStatePath(
            this.props,
            this.props.formElementContext.completeStatePath,
          ),
        }}
      >
        <>
          {value === undefined &&
            !this.props.elementName?.includes(".") &&
            window.location.hostname.includes("localhost") && (
              <p style={{ color: "red", fontWeight: "bold" }}>
                [form] value for element &quot;{this.props.elementName}&quot; is
                undefined
              </p>
            )}
          {this.props.children({
            value,
            validation,
            hideComponent: this.state.hideComponent,
            elementName: this.props.elementName,
            valueChangeObs: this.valueChangeObs,
            rootValueChangeObs: this.props.stateDispatcherContext
              .rootValueChangeObs
              ? this.props.stateDispatcherContext.rootValueChangeObs
              : this.valueChangeObs,
            onChange: this.onChange,
            statePath,
            getValue: this.getValue,
          })}
        </>
      </formElementContext.Provider>
    );
  }
}

import { WEBSOCKET_STATUS } from "@recare/core/consts";
import {
  ClientOptions,
  EventConnectedListener,
  EventMessageListener,
  EventPingListener,
} from "graphql-ws";
import { useWebSocketContext } from "./WebSocketContext";
import { WebSocketConfig } from "./config";

export const useGetWebSocketEventHandlers = () => {
  const { _setStatus } = useWebSocketContext();

  return (): ClientOptions["on"] => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    let activeSocket: WebSocket | undefined;

    const handlePing: EventPingListener = () => {
      // on ping set timeout to wait for pong
      timeout = setTimeout(() => {
        if (activeSocket?.readyState === WebSocket.OPEN) {
          // if not received set status to closing as the actual close wont happen until network is reestablished
          _setStatus(WEBSOCKET_STATUS.CLOSING);

          // once closed it will reattempt to establish websocket connection
          console.info("websocket request timeout: no pong received");
          activeSocket?.close(4408, "request timeout: no pong received");
        }
      }, WebSocketConfig.PONG_TIME_OUT_DURATION);
    };

    const handlePong: EventMessageListener = (message) => {
      if (message.type !== "pong") {
        return;
      }
      // if pong received clear the timeout to ensure connection is still set as connected
      if (timeout) clearTimeout(timeout);
    };

    const handleConnected: EventConnectedListener = (socket) => {
      activeSocket = socket as WebSocket;
      _setStatus(WEBSOCKET_STATUS.CONNECTED);
    };

    return {
      connected: handleConnected,
      ping: handlePing,
      message: handlePong,
      connecting: () => {
        _setStatus(WEBSOCKET_STATUS.CONNECTING);
      },
      closed: () => {
        _setStatus(WEBSOCKET_STATUS.CLOSED);
      },
      error: () => {
        _setStatus(WEBSOCKET_STATUS.ERROR);
      },
    };
  };
};

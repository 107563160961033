import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { useTranslations } from "@recare/translations";
import RSButton from "ds/components/RSButton";
import { ERROR_COLOR } from "ds/materials/colors";
import { padding, sizing } from "ds/materials/metrics";

type ErrorSnackProps = {
  count: number;
  reset: () => void;
};

export default function ErrorSnackbar({ count, reset }: ErrorSnackProps) {
  const translations = useTranslations();

  return (
    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open>
      <SnackbarContent
        sx={{
          backgroundColor: ERROR_COLOR,
          marginBottom: sizing(2),
          padding: padding(0, 3),
        }}
        aria-describedby="network-checker-snackbar"
        message={translations.networkChecker.retryingMessage({
          count: count.toString(),
        })}
        action={[
          <RSButton
            color="error"
            id="retry"
            inverted
            key="network-checker-retry"
            loading="na"
            onClick={reset}
            variant="text"
          >
            {translations.networkChecker.retry}
          </RSButton>,
        ]}
      />
    </Snackbar>
  );
}

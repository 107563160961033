import { addMinutes, endOfDay, isAfter, isBefore, parseISO } from "date-fns";
import { v4 as uuidv4 } from "uuid";

// Types
type LocalStorageSesssion = {
  expiryDate: string;
  id: string;
  trackingEventsCount: number;
};
//
export const DEFAULT_SESSION_EXPIRY_IN_MINUTES = 30;
export const DEFAULT_LOCAL_STORAGE_KEY = "trackingSession";

export class Session {
  #id: string;
  #expiryDate: Date;
  #trackingEventsCount: number;
  #sessionExpiryInMinutes: number;
  #localStorageKey: string;

  constructor(
    {
      sessionExpiryInMinutes = DEFAULT_SESSION_EXPIRY_IN_MINUTES,
      localStorageKey = DEFAULT_LOCAL_STORAGE_KEY,
    } = {
      sessionExpiryInMinutes: DEFAULT_SESSION_EXPIRY_IN_MINUTES,
      localStorageKey: DEFAULT_LOCAL_STORAGE_KEY,
    },
  ) {
    this.#sessionExpiryInMinutes = sessionExpiryInMinutes;
    this.#localStorageKey = localStorageKey;
    const existingSession = Session.getSessionFromLocalStorage(localStorageKey);

    if (
      existingSession?.id &&
      !this.hasExpired(parseISO(existingSession.expiryDate))
    ) {
      this.#id = existingSession.id;
      this.#expiryDate = parseISO(existingSession.expiryDate);
      this.#trackingEventsCount = existingSession.trackingEventsCount;
    } else {
      this.setNewSession();
    }
  }

  setNewSession() {
    this.#id = this.getUniqueId();
    this.#expiryDate = addMinutes(new Date(), this.#sessionExpiryInMinutes);
    this.#trackingEventsCount = 1;
    this.updateLocalStorage({
      id: this.#id,
      createdAt: new Date(),
      expiryDate: this.#expiryDate,
      trackingEventsCount: this.#trackingEventsCount,
    });
  }

  get id() {
    return this.#id;
  }

  getUniqueId() {
    return `${uuidv4()}.${new Date().getTime()}`;
  }

  reset() {
    this.setNewSession();
  }

  hasExpired(expiryDate = this.#expiryDate) {
    return isBefore(expiryDate, new Date());
  }

  moveExpiryAhead(minutes = this.#sessionExpiryInMinutes) {
    const now = new Date();
    const newExpiryDate = addMinutes(now, minutes);
    const midNight = endOfDay(now);

    // Reset sessions after midnight
    if (isAfter(newExpiryDate, midNight)) {
      console.log("[SESSION] - reset after midnight");

      this.reset();
    } else {
      this.#expiryDate = newExpiryDate;
    }

    this.updateLocalStorage({ expiryDate: this.#expiryDate });
  }

  get trackingEventsCount() {
    return this.#trackingEventsCount;
  }

  incrementTrackingEventsCount() {
    this.#trackingEventsCount++;

    this.updateLocalStorage({
      trackingEventsCount: this.#trackingEventsCount,
    });
  }

  updateLocalStorage(
    newSession: {
      createdAt?: Date;
      expiryDate?: Date;
      id?: string;
      trackingEventsCount?: number;
    } = {},
  ) {
    const existingSession = Session.getSessionFromLocalStorage(
      this.#localStorageKey,
    );
    if (existingSession) {
      localStorage.setItem(
        this.#localStorageKey,
        JSON.stringify({ ...existingSession, ...newSession }),
      );
    } else {
      localStorage.setItem(this.#localStorageKey, JSON.stringify(newSession));
    }
  }

  static getSessionFromLocalStorage(
    localStorageKey: string,
  ): LocalStorageSesssion | null {
    let existingSession = null;
    try {
      const trackingSession = localStorage.getItem(localStorageKey);
      if (trackingSession) {
        existingSession = JSON.parse(trackingSession);
      }
    } catch (err) {
      console.error(err);
    }
    return existingSession;
  }
}

import { AnyObject } from "@recare/core/types";
import { fromJS, List, Map } from "immutable";
import { ConversionJob, ConversionJobs, ConversionModel } from "./types";

export function convertModelDefinition(
  model: ConversionModel,
  currentInPath = "",
  parentOutPath = "",
): ConversionJobs {
  if (typeof model !== "object" || model == null) {
    return List<ConversionJob>();
  }
  const currentNodeOutPath =
    "out" in model || "out_path" in model
      ? model.out || model.out_path || ""
      : "";
  const currentOutPath = `${
    parentOutPath !== ""
      ? `${parentOutPath}.${currentNodeOutPath}`
      : currentNodeOutPath
  }`;
  return List<ConversionJob>()
    .concat(
      currentInPath !== ""
        ? List.of({
            in: currentInPath,
            out: currentOutPath,
            show: "show" in model ? model.show : undefined,
            convertIn: "convertIn" in model ? model.convertIn : undefined,
            convertOut: "convertOut" in model ? model.convertOut : undefined,
            default: model.default,
            validate: "validate" in model ? model.validate : undefined,
          })
        : List(),
    )
    .toList()
    .concat(
      Object.keys(model)
        .filter(
          (nodeKey) =>
            ![
              "convertIn",
              "show",
              "convertOut",
              "out",
              "out_path",
              "complex",
              "default",
              "validate",
            ].includes(nodeKey),
        )
        .reduce((red: any, nodeKey) => {
          const childNode = model[nodeKey];
          const childInPath = currentInPath
            ? `${currentInPath}.${nodeKey}`
            : nodeKey;
          return red.concat(
            convertModelDefinition(
              childNode as ConversionModel,
              childInPath,
              currentOutPath,
            ),
          );
        }, List()),
    )
    .toList();
}

export function convertIn(jobs: ConversionJobs) {
  return function convertInModelValue(value: any, props?: any) {
    if (value == null) return {};
    const immutableValue = fromJS(value);
    if (props?.debug && console.groupCollapsed) {
      console.groupCollapsed("Form ConvertIn");
    }
    const convertedValue = jobs
      .reduceRight((red: any, job: any) => {
        const inPath = job.in.split(".");
        const outPath = job.out.split(".");
        const notConvertedValue =
          inPath.length === 1 && inPath[0] === ""
            ? immutableValue
            : immutableValue.getIn(
                inPath,
                red.getIn(
                  outPath,
                  typeof job.default === "function"
                    ? job.default(props)
                    : job.default,
                ),
              );
        const inValue =
          typeof job.convertIn === "function"
            ? job.convertIn(notConvertedValue, props, immutableValue)
            : notConvertedValue;
        let newRed;
        if (
          (red.getIn(outPath) != null &&
            inValue != null &&
            inValue !== false) ||
          (outPath.length === 1 && outPath[0] === "")
        )
          newRed = red;
        else {
          newRed = red.setIn(outPath, fromJS(inValue));
        }
        if (props?.debug) {
          console.log(
            "ConvertIn reducing...",
            "\nState (initial, red):",
            immutableValue,
            red,
            "\nJob:",
            job,
            "\nIn:",
            inPath,
            inValue,
            "converted from",
            notConvertedValue,
            "\nOut:",
            outPath,
            "\nNew reduction:",
            newRed,
          );
        }
        return newRed;
      }, Map())
      .toJS();
    if (props?.debug) {
      console.log(
        "ConvertIn converted",
        value,
        "into",
        convertedValue,
        "with props",
        props,
        "and jobs",
        jobs,
      );
      if (console.groupEnd) {
        console.groupEnd();
      }
    }
    return convertedValue;
  };
}

export function convertOut(jobs: ConversionJobs) {
  return function convertOutModelValue(value: any, props?: any) {
    if (value == null) return {};
    const immutableValue = fromJS(value);
    if (props?.debug && console.groupCollapsed) {
      console.groupCollapsed("Form ConvertOut");
    }
    const convertedValue = jobs
      .reduceRight((red: any, job: any) => {
        const outPath = job.out.split(".");
        const notConvertedValue = immutableValue.getIn(outPath);
        const outValue =
          typeof job.convertOut === "function"
            ? job.convertOut(notConvertedValue, props, immutableValue)
            : notConvertedValue;
        if (outValue === undefined) {
          return red;
        }
        const inPath = job.in.split(".");
        let newRed;
        if (
          ((red.getIn(inPath) != null && outValue != null) ||
            (inPath.length === 1 && inPath[0] === "")) &&
          !job.convertOut
        )
          newRed = red;
        else {
          newRed = red.setIn(inPath, outValue);
        }
        if (props?.debug) {
          console.log(
            "ConvertOut reducing...",
            "\nState (initial, red):",
            immutableValue,
            red,
            "\nJob:",
            job,
            "\nOut:",
            outPath,
            outValue,
            "converted from",
            notConvertedValue,
            "\nIn:",
            inPath,
            "\nNew reduction:",
            newRed,
          );
        }
        return newRed;
      }, Map())
      .toJS();
    if (props?.debug) {
      console.log(
        "ConvertOut converted",
        value,
        "into",
        convertedValue,
        "with props",
        props,
        "and jobs",
        jobs,
      );
      if (console.groupEnd) {
        console.groupEnd();
      }
    }
    return convertedValue;
  };
}

export function validateModel(jobs: ConversionJobs) {
  return function validateState(
    value: any,
    props: AnyObject = {},
  ): AnyObject | boolean | string {
    const immutableValue = fromJS(value);
    const wholeValidation = jobs.reduceRight((red: any, job: any) => {
      const outPath = job.out.split(".");
      const fieldValue = immutableValue.getIn(outPath);
      if (
        outPath.length === 1 &&
        outPath[0] === "" &&
        typeof job.validate === "function"
      )
        throw new Error(
          `You can't use validation if you don't have any out, either in the current node or a parent one. At ${job.in}`,
        );
      const validation =
        typeof job.validate === "function"
          ? job.validate(fieldValue, props, immutableValue)
          : true;
      if (
        validation === true ||
        validation === null ||
        validation === undefined
      ) {
        return red;
      }
      return (typeof red === "object" ? red : Map()).setIn(outPath, validation);
    }, true);

    if (typeof wholeValidation === "object") return wholeValidation.toJS();
    return wholeValidation;
  };
}
